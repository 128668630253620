<template>
  <div class="word-index">
    <div class="scroll-container">
      <b-row class="d-flex flex-wrap justify-content-center">
        <b-col
          v-for="(item, index) in words"
          :key="item.id"
          cols="12" sm="6" md="3" lg="3"
          class="mb-3"
        >
          <div
            v-b-tooltip.hover
            class="square"
            :title="item.vocab_word"
            :class="{
              'mouse-cursor': !item.isPassed && !item.isFailed
            }"
            @click="handleSelectProblem(item)"
          >
            <div
              class="square-content"
              :class="{
                'selected-problem': selectedProblem === item.id,
                'bg-success text-white': item.isPassed,
                'bg-danger text-white': item.isFailed
              }"
            >
              <span>{{ index + 1 }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    words: {
      type: Array,
      default: () => [],
    },
    selectedProblem: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    handleSelectProblem(item) {
      if (!item.isPassed && !item.isFailed) {
        this.$emit('selectProblem', item.id)
      }
    },
  },
}
</script>

<style scoped>

h1 {
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  color: #2c3e50;
}

.scroll-container {
  max-height: 80vh; /* Make the container scrollable if content overflows */
  overflow-y: auto; /* Enables vertical scroll */
}

.mouse-cursor {
  cursor: pointer;
}

.square {
  width: 100%;
  padding-bottom: 100%;
  border: 2px solid #ecf0f1;
  border-radius: 10px;
  position: relative;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.square:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.15);
}

.square-content {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
  color: #2c3e50;
}

.selected-problem {
  background-color: #ff9f43;
  color: white !important;
  box-shadow: 0px 4px 6px rgba(255, 159, 67, 0.6);
}

.bg-success {
  background-color: #28a745 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.text-white {
  color: white !important;
}

@media (max-width: 576px) {
  .square-content {
    font-size: 1.5rem;
  }
}
</style>
